<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Transazioni di Cassa Pagamenti"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
     
      <v-btn text link @click="goToNew" :disabled="isLicenseBlocked">
        <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
      </v-btn>

      <ExportExcelTable
        :worksheetName="worksheetName"
        :fileName="fileName"
        :fieldsToExport="fieldsToExport"
        :fetch="fetchExcel"
      > </ExportExcelTable>

       <v-btn
        icon
        @click="toggleBarcode"
        :color="!!ghost ? 'red' : ''"
      >
      <v-icon>mdi-shield-account-variant</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
       <div class="d-flex justify-left align-left mb-3" style="width: 100%; height: fit-content;">
        <AdvancedFilter
          style="width:99%; height: 100%;"
          v-model="filtersValue"
          :filters="filterTypes"
          :advance-filters="advanceFilters"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-name="{filter}">
            Nome: {{ filter.value }}
          </template>

          <template v-slot:custom-status="{filter}">
            <v-row class="pa-6">
              <v-col>
                <v-select
                  v-model="filter.value"
                  :items="[
                            { value: 'finished', label: 'Pagato Totalmente' },
                            { value: 'inProgress', label: 'Pagato Parzialmente' },
                            { value: 'beginning', label: 'Non Pagato' },
                          ]"
                  item-text="label"
                  item-value="value"
                  :label="filter.label"
                  multiple
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </template>
          <!--<template v-slot:chip-custom-status="{filter}">
            {{filter.value.join(', ')}}
          </template>-->

          <template v-slot:custom-type="{filter}">
            <v-row class="pa-5">
              <v-col>
                <v-select
                  v-model="filter.value"
                  :items="typesList"
                  item-text="name"
                  item-value="type"
                  label="Tipo"
                  dense
                  hide-details
                  multiple
                >
                </v-select>
              </v-col>
            </v-row>
          </template>
          <template v-slot:chip-custom-type="{filter}">
            {{getNameFromType(filter.value).join(', ')}}
          </template>

          <template v-slot:custom-operator="{filter}">
            <v-row class="pb-5 pl-5">
              <v-col>
                <OperatorsAutocomplete
                  v-model="filter.value"
                  :multiple="false"
                  :filled="false"
                  dense
                  label="Operatore"
                >
                </OperatorsAutocomplete>
              </v-col>
            </v-row>
          </template>
          <template v-slot:chip-custom-operator="{filter}">
            {{'Operatore: ' + filter.value}}
          </template>
        </AdvancedFilter>
      </div>
      <div style="height: 83%; overflow-y: hidden" ref="dataTableWrapper">
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento Transazioni di Cassa Pagamenti ..."
          :headers="headers"
          :loading="loading"
          :items="filteredPaymentDebits"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          :show-select="false"
          @dblclick:row="handleEditDoubleClick"
          @edit="handleEdit"
          @delete="handleDelete"
        >
          <template v-slot:custom-amount="{item}">
            <span v-if="!!item.amount">
              {{Number(item.amount).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}
            </span>
            <span v-else>
              Non specificato
            </span>
          </template>
          <template v-slot:custom-operator="{item}">
            <span v-if="(item.type == 'operator' || item.type == 'intermediaryOperator') && !!item.operator">
              {{item.operator.firstname}} {{item.operator.lastname}}
            </span>
            <span v-if="item.type == 'supplier' && !!item.supplier">
              {{item.supplier.businessName}}
            </span>
          </template>
          <template v-slot:custom-status="{item}">
            <v-chip 
              v-if="item.status == 'beginning'" 
              color="error" 
              label
            >
              Non pagato
            </v-chip>
            <span 
              v-else-if="item.status == 'inProgress'"
              color="warning" 
              label
            >
              Pagato parzialmente
            </span>
            <v-chip
              v-else-if="item.status == 'finished' && !Number(item.transactions[0].ghostPaid)"
              color="success" 
              label
            >
              Pagato totalmente
            </v-chip>
            <v-chip
              v-else-if="item.status == 'finished' && !!Number(item.transactions[0].ghostPaid)"
              color="red" 
              label
              class="white--text"
            >
             Pagato totalmente
            </v-chip>
          </template>

          <template v-slot:externalFooter>
            <TypeDataTable
              :headers="headers"
              :items="totalRow"
              :show-select="false"
              :show-actions="false"
              disablePagination
            >
            <template v-slot:custom-amount="{item}">
                <span v-if="!!item.amount">
                  {{Number(item.amount).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}
                </span>
                <span v-else>
                  Non specificato
                </span>
              </template>
              <template v-slot:custom-operator="{item}">
                <span v-if="(item.type == 'operator' || item.type == 'intermediaryOperator') && !!item.operator">
                  {{item.operator.firstname}} {{item.operator.lastname}}
                </span>
                <span v-if="item.type == 'supplier' && !!item.supplier">
                  {{item.supplier.businessName}}
                </span>
              </template>
            </TypeDataTable>
          </template>

        </TypeDataTable>
        <StandardDialog
          v-model="showBarcodeUpdate"
          title="Modifica Transazione"
          :dialog-height="null"
          dialog-max-width="500px"
          persistent
        >
          <ManualBarcodeInput
            v-model="barcodePermission"
            @confirm="updatePermission"
          >
          </ManualBarcodeInput>
        </StandardDialog>
      </div>
      <StandardDialog
        v-model="dialogBarcode"
        title="Area Riservata Sviluppatori" 
        :dialog-height="null"
        dialog-max-width="500px"
      >
        <ManualBarcodeInput
          v-model="ghostBarcode"
          scan-your-barcode-message="Immettere Codice" 
          @confirm="activeGhost"
        >
        </ManualBarcodeInput>
      </StandardDialog>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardMenu from '@/components/common/StandardMenu.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import { SearchBar } from "likablehair-ui-components";
import paymentDebitsService from '@/services/paymentDebits/paymentDebits.service.js'
import operatorService from "@/services/operators/operators.service.js"
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import StandardDialog from '@/components/common/StandardDialog.vue';
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import OperatorsAutocomplete from "@/components/common/OperatorsAutocomplete.vue";
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import dateUtils from "@/mixins/common/dateUtils"
import cashDeskService from "@/services/cashDesks/cashDesks.service.js";

export default {
  name: "PaymentTransactionsGivenList",
  components: {
    FullScreenDialog,
    SearchBar,
    TypeDataTable,
    StandardMenu,
    AdvancedFilter,
    ManualBarcodeInput,
    StandardDialog,
    OperatorsAutocomplete,
    ExportExcelTable
  },
  data: function() {
    let props = this.$router.resolve({name: 'Payments'});

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      paymentDebits: [],
      headers: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 0,
      resizeListener: undefined,
      /*currentFilters: { 
        types: ['operator', 'supplier', 'intermediaryOperator'],
        dateTransaction: new Date().toISOString().substring(0,10),
      },*/
      dialogBarcode: false,
      ghostBarcode: undefined,
      ghost: false,
      filterTypes: [
        { type: 'date', operator: 'between', field: 'openedAt', name: 'Data', label: 'Del giorno', color: "", value: [undefined, undefined] },
        { type: 'custom', operator: 'custom', field: 'operator', name: 'Operatore', label: 'Label', color: "", icon: 'mdi-account', value: undefined },
        { type: 'number', operator: 'equal', field: 'amount', name: 'Ammontare', label: 'Ammontare', color: "", value: undefined },
        { type: 'custom', operator: 'custom', field: 'status', name: 'Stato', label: 'Stato', color: "", icon: 'mdi-list-status', value: undefined },
      ],
      advanceFilters: [
        { type: 'custom', operator: 'custom', field: 'type', name: 'Tipo', label: 'Tipo', color: "", value: [
          { type: 'operator', name: 'Operatori' },
          { type: 'supplier', name: 'Fornitori' },
          { type: 'intermediaryOperator', name: 'Operatori Intermediari' },
        ], icon: 'mdi-format-list-bulleted-type' },
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'name', name: 'Nome', label: 'Nome', color: "", value: undefined, icon: 'mdi-text-search' },
      filtersValue: [
      ],
      typesList: [
        { type: 'operator', name: 'Operatori' },
        { type: 'supplier', name: 'Fornitori' },
        { type: 'intermediaryOperator', name: 'Operatori Intermediari' },
      ],
      totalRow: undefined,
      worksheetName: "",
      fileName:"",
      fieldsToExport: {},
      selectedUpdateTransaction: undefined,
      barcodePermission: undefined,
      showBarcodeUpdate: false,
      isLicenseBlocked: undefined
    }
  },
  mixins:[dateUtils],
  props: {
    filters: {
      type: Array,
      default: function() {
        let tmpDate = new Date();
        tmpDate.setHours(0, 0, 0, 0);
        return [
          { type: 'date', name: 'Data', field: 'openedAt', operator: 'equal', value: tmpDate }
        ]
      }
    }
  },
  mounted: function() {
    this.fetchHeaders()
    this.filtersValue = this.filters
    this.fetchPaymentDebits()

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight *0.72

    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight *0.72
    }
    this.resizeListener = this.resizeListener.bind(this)
    window.addEventListener('resize', this.resizeListener)
    
    this.fieldsToExport = paymentDebitsService._fieldsToExport(this.ghost);
    this.worksheetName = "Export Transazioni ";
    this.fileName =
      "TransazioniDiCassa-Pagamenti_" + this._buildDateToExport(new Date()) +
      ".xls";
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    today(){
      let tmpDate = new Date();
      tmpDate.setHours(0, 0, 0, 0);
      return tmpDate
    },
    fetchPaymentDebits() {
      this.loadingItems = true
      let filters = [...this.filtersValue, 
        { type: 'type', name: 'Tipo', field: 'type', operator: 'in', value: ['operator', 'customer', 'supplier'] }
      ];

      paymentDebitsService.list(this.page, this.rowPerPage, filters).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page && this.totalPages != 0) {
          this.page = this.totalPages
        }

        this.totalRow = [{ amount: Number(results.totalAmount) }]

        this.paymentDebits = results.rows.filter(item => !this.ghost ? !Number(item.transactions[0].ghostPaid) : true)
        if(!this.ghost) {
          let ghostAmount = results.rows.map(item => Number(item.transactions[0].ghostPaid)).reduce((a, b) => Number((a + b).toFixed(2)), 0)
          this.totalRow[0].amount -= ghostAmount
        }
        this.loadingItems = false
      })
    },
    fetchHeaders() {
      this.loadingHeaders = true
      paymentDebitsService.operatorFields().then((headers) => {
        this.headers = headers
        this.loadingHeaders = false
      })
    },
    async fetchExcel(){
      let filters = [...this.filtersValue];
      
      let paymentDebitsToExport = await paymentDebitsService.list(undefined, 5000, filters)
      paymentDebitsToExport = paymentDebitsToExport.rows.filter(item => !this.ghost ? !Number(item.transactions[0].ghostPaid) : true)

      paymentDebitsToExport.map(p => {
        if((p.type == 'operator' || p.type == 'intermediaryOperator') && !!p.operator)
          p.fullname = p.operator.firstname + " " + p.operator.lastname
        else if (p.type == 'supplier' && !!p.supplier)
          p.fullname = p.supplier.businessName
      })

      if(!!paymentDebitsToExport)
        return paymentDebitsToExport
    },
    updatePermission() {
      operatorService.canPerformOperation(this.barcodePermission, "Payments", "manage").then((result) => {
        if (result) {
          this.$router.push({
            name: 'PaymentTransactionsGivenEditForm',
            params: {
              id: this.selectedUpdateTransaction.id,
              filters: this.filtersValue
            },
            query: {
              pathToGoBack: this.$router.resolve({name: 'PaymentTransactionsGivenList'}).href,
              pathName: 'PaymentTransactionsGivenList',
            }
          })
        } else {
          alert("Non hai i permessi per eseguire questa azione!")   
        }
      }).catch((error) => {
        console.log(error)
        alert("Badge non riconosciuto")
      })
      this.showBarcodeUpdate = false
    },
    handleEdit(paymentDebit) {
      this.selectedUpdateTransaction = paymentDebit
      this.barcodePermission = undefined
      this.showBarcodeUpdate = true
    },
    handleEditDoubleClick(row, {item: paymentDebit}) {
      this.handleEdit(paymentDebit)
    },
    handleDelete(paymentDebit) {
      let confirmed = confirm('Sei sicuro di voler eliminare la spesa?')

      if(confirmed) {
        this.loadingItems = true
        paymentDebitsService.delete(paymentDebit).then(() => {
          this.fetchPaymentDebits()
          this.loadingItems = false
        })
      }
    },
    async goToNew() {
      let openedCashDesk = await cashDeskService.getOpened()
      if(!openedCashDesk){
        this.$delegates.snackbar('Per creare una transazione di cassa pagamento aprire la cassa')
        return
      } 
      this.$router.push({
        name: 'PaymentTransactionsGivenNewForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'PaymentTransactionsGivenList'}).href
        }
      })
    },
    applyFilters(filters) {
      this.fetchPaymentDebits();
    },
    toggleBarcode(){
      this.dialogBarcode = true
    },
    activeGhost(){
      operatorService.canPerformOperation(this.ghostBarcode, "Ghost").then((result) => {
        if (result) {
          this.ghost = !this.ghost
          this.applyFilters()
        } else {
          alert("Non hai i permessi per eseguire questa azione!")
        }
      }).catch(() => {
        alert("Badge non riconosciuto")
      })
      this.ghostBarcode = false
      this.dialogBarcode = false
    },
    getNameFromType(array){
      let arrayName = []
      let dictionary = {
        operator: "Operatori",
        supplier: "Fornitori",
        intermediaryOperator: "Operatori intermediari"
      }
      array.forEach((item) => {
        arrayName.push(dictionary[item.type])
      })

      return arrayName
    },
  },
  computed: {
    filteredPaymentDebits() {
      return this.paymentDebits
    },
    loading() {
      return this.loadingItems || this.loadingHeaders
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    },
  },
  watch: {
    page() {
      this.fetchPaymentDebits()
    },
    rowPerPage() {
      this.fetchPaymentDebits()
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
}
</script>